// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const GoalQuarterIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'goal-quarter'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 17.5229 6.47713 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47713 17.5229 2 12 2C6.47713 2 2 6.47713 2 12ZM11.9992 5.33333C10.6808 5.33374 9.39217 5.72477 8.29598 6.4576C7.19979 7.19002 6.3453 8.23128 5.84074 9.44954C5.33618 10.6674 5.20435 12.0077 5.46151 13.3009C5.71867 14.5944 6.35343 15.7821 7.28564 16.7144C7.90535 17.3341 8.63778 17.8223 9.43896 18.1556C9.84342 18.3236 10.2654 18.4522 10.6991 18.5385C11.9923 18.7957 13.3326 18.6638 14.5505 18.1593C15.2605 17.8651 15.9107 17.4521 16.4743 16.9418C16.8775 16.5764 17.2368 16.1614 17.5424 15.704C18.2752 14.6078 18.6663 13.3192 18.6667 12.0008L12 12L11.9992 5.33333Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
GoalQuarterIcon.displayName = 'GoalQuarterIcon'
export { GoalQuarterIcon }
