import React from 'react'
import clsx from 'clsx'
import type * as Polymorphic from '@radix-ui/react-polymorphic'

import textAlignStyles from '../../styles/atoms/text-align.module.css'

import styles from './Heading.module.css'

type HeadingOwnProps = {
  /**
   * Render as a different element, by default is inferred by size
   */
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'

  /**
   * Controls visual size of the heading
   * @default medium
   */
  size?: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall'

  /**
   * Align text to the left, center or right
   * @default left
   */
  align?: 'left' | 'center' | 'right'
  /**
   * Children to render inside the heading
   */
  children?: React.ReactNode
  /**
   * Additional CSS classes
   */
  className?: string
  /**
   * Prevent text from wrapping
   */
  truncate?: boolean | 'ellipsis'
}

const levelBySize = {
  xlarge: 1,
  large: 2,
  medium: 3,
  small: 4,
  xsmall: 5,
} as const

type PolymorphicHeading = Polymorphic.ForwardRefComponent<'h3', HeadingOwnProps>

/**
 * Semantic heading element
 */
const Heading = React.forwardRef(
  (
    {
      children,
      as,
      size = 'medium',
      align = 'left',
      className,
      truncate,
      ...headingProps
    }: HeadingOwnProps,
    forwardedRef,
  ) => {
    const Tag = as || `h${levelBySize[size]}`

    return (
      <Tag
        className={clsx(
          styles.base,
          styles[size],
          textAlignStyles[align],
          truncate && styles.truncate,
          className,
        )}
        ref={forwardedRef}
        {...headingProps}
      >
        {children}
      </Tag>
    )
  },
) as PolymorphicHeading

Heading.displayName = 'Heading'

export { Heading, HeadingOwnProps as HeadingProps }
