import React from 'react'
import clsx from 'clsx'

import styles from './Badge.module.css'

type BadgeProps = React.HTMLAttributes<HTMLSpanElement> & {
  /**
   * Size of the badge
   * @default medium
   */
  size?: 'xsmall' | 'small' | 'mid' | 'medium' | 'large'
  /**
   * Variant of the badge
   * @default neutral
   */
  variant?: 'neutral' | 'light' | 'brand'
  /**
   * Contents of the badge
   */
  children: React.ReactNode
}

const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      size = 'medium',
      variant = 'neutral',
      children,
      className,
      ...props
    }: BadgeProps,
    forwardedRef,
  ) => {
    return (
      <span
        ref={forwardedRef}
        className={clsx(styles.badge, styles[size], styles[variant], className)}
        {...props}
      >
        {children}
      </span>
    )
  },
)

Badge.displayName = 'Badge'

export { Badge, BadgeProps }
