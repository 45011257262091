import { useState, useLayoutEffect } from 'react'

type Breakpoint = 'initial' | 'sm' | 'md' | 'lg' | 'xl'

const breakpoints: Breakpoint[] = ['sm', 'md', 'lg', 'xl']
const breakpointValues = [640, 768, 1024, 1280]

export function useBreakpoint(): Breakpoint {
  const [activeBreakpoint, setActiveBreakpoint] =
    useState<Breakpoint>('initial')

  useLayoutEffect(() => {
    // Create media query strings for each breakpoint
    const mediaQueries = breakpointValues.map(
      (value) => `(min-width: ${value}px)`,
    )

    // Function to determine active breakpoint
    const getActiveBreakpoint = () => {
      const activeIndex = mediaQueries.reduce((acc, query, index) => {
        if (window.matchMedia(query).matches) {
          return index
        }
        return acc
      }, -1)

      return activeIndex >= 0 ? breakpoints[activeIndex] : 'initial'
    }

    // Update active breakpoint
    const updateBreakpoint = () => {
      setActiveBreakpoint(getActiveBreakpoint())
    }

    // Set initial value
    updateBreakpoint()

    // Add listeners for each breakpoint
    const mediaListeners = mediaQueries.map((query) => {
      const mql = window.matchMedia(query)
      mql.addEventListener('change', updateBreakpoint)
      return mql
    })

    // Cleanup
    return () => {
      mediaListeners.forEach((mql) => {
        mql.removeEventListener('change', updateBreakpoint)
      })
    }
  }, [])

  return activeBreakpoint
}
