import {
  UploadIcon as LucideUploadIcon,
  CircleIcon as LucideCircleIcon,
  FolderPlusIcon as LucideFolderPlusIcon,
} from 'lucide-react'

import { makeLucideIcon } from './makeLucideIcon'

/*
 * This file contains icons from Lucide (https://lucide.dev)
 *
 * Full Icon list here https://lucide.dev/icons/
 *
 * To add a new Lucide icon:
 * 1. Import the icon from 'lucide-react', e.g., import { IconName } from 'lucide-react'
 * 2. Export it using makeLucideIcon, e.g., export const IconName = makeLucideIcon(LucideIconName, 'IconName')
 * 3. The icon will automatically inherit Buffer's styling system
 *
 * For custom icons (not from Lucide), see the readme in the popcorn directory, under the section "How to generate icons"
 */

/**
 * Upload Icon
 */
export const UploadIcon = makeLucideIcon(LucideUploadIcon, 'UploadIcon')

/**
 * Circle Small Icon
 */
export const CircleIcon = makeLucideIcon(LucideCircleIcon, 'circle')

/**
 * Folder Plus Icon
 */
export const FolderPlusIcon = makeLucideIcon(
  LucideFolderPlusIcon,
  'FolderPlusIcon',
)
