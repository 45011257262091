import {
  addCachedImpression,
  getCachedImpressionKeys,
} from './cachedImpressionKeys'

const dismissedTreatments = ['control', 'ineligible']

export default function trackImpression({
  impression,
}: SplitIO.ImpressionData) {
  // don't track control treatments, usually means sdk not ready
  if (dismissedTreatments.includes(impression.treatment)) {
    return
  }
  // Should be an org id, so 'key' means SDK is not ready yet
  if (impression.keyName === 'key') {
    return
  }

  // Cache an 'impression' so we don't track the same evaluation more than once for
  // a single page session
  const impressionKey = `${impression.feature}-${impression.keyName}-${impression.treatment}`
  if (!getCachedImpressionKeys().includes(impressionKey)) {
    addCachedImpression(impressionKey)

    // We need Segment :)
    if (!window.analytics) {
      return
    }

    // Track this as an experiment enrolled if the feature in question starts
    // with 'eid' or 'geid'
    if (
      impression.feature.toLowerCase().startsWith('geid') ||
      impression.feature.toLowerCase().startsWith('eid') ||
      // HACK: track one additional split for publish revamp.
      // Can be removed when this split is rolled out 100%
      impression.feature.includes('publish-revamp')
    ) {
      window.analytics.track('Experiment Enrolled', {
        experimentId: impression.feature,
        experimentGroup: impression.treatment,
        product: 'buffer',
        sourceFramework: 'split',
        view: window.location.href,
        organizationId: impression.keyName,
      })
    }
  }
}
