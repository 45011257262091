import * as React from 'react'
import { Drawer as DrawerPrimitive } from 'vaul'
import clsx from 'clsx'

import usePortalContainer from '../../helpers/usePortalContainer'
import { Heading } from '../Heading'
import { Paragraph } from '../Paragraph'

import styles from './Drawer.module.css'
import { useBreakpoint } from '../../hooks/useBreakpoint'

const DrawerInternalContext = React.createContext(false)

/**
 * This hook is used to check if you are in a Drawer Context.
 * This needed to check if we can use drawer primitives.
 * This is meant for internal use only not for consumers.
 * @returns {boolean}
 */
const useIsInDrawer = () => {
  const isInDrawer = React.useContext(DrawerInternalContext)
  return isInDrawer
}

type DrawerProps = React.ComponentProps<typeof DrawerPrimitive.Root> & {
  /**
   * Whether the drawer is open.
   */
  open?: boolean
  /**
   * Whether the drawer is open by default.
   * @default false
   */
  defaultOpen?: boolean
  /**
   * Callback function that is called when the drawer is opened or closed.
   */
  onOpenChange?: (open: boolean) => void
  /**
   * Direction of the drawer.
   * @default 'bottom'
   */
  direction?: 'bottom' | 'left' | 'right'
  /**
   * Internal Prop – not to be used by the consumer
   * This prevents initializing the drawer context when it is not needed
   * @default false
   */
  _preventInternalContext?: boolean
}

/**
 * Drawer is a sheet that slides in from the bottom of the screen.
 * It is designed for mobile devices. Mainly it is used as a replacement for dialog in mobile devices, but can be used on its own.
 * `Dialog` component comes built-in with this behavior out of the box for smaller screens.
 */
const Drawer = (props: DrawerProps) => {
  const shouldScaleBackground = useBreakpoint() === 'initial'
  const isInDrawer = useIsInDrawer()

  // If we are in a drawer, we need to return a nested drawer
  if (isInDrawer || props?._preventInternalContext) {
    return (
      <DrawerPrimitive.NestedRoot
        {...props}
        shouldScaleBackground={shouldScaleBackground}
      />
    )
  }

  return (
    <DrawerInternalContext.Provider value={true}>
      <DrawerPrimitive.Root
        shouldScaleBackground={shouldScaleBackground}
        {...props}
      />
    </DrawerInternalContext.Provider>
  )
}
Drawer.displayName = 'Drawer'

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={clsx(styles.overlay, className)}
    {...props}
  />
))
DrawerOverlay.displayName = 'Drawer.Overlay'

const DrawerInnerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DrawerPrimitive.Content
    ref={ref}
    data-drawer-content
    className={clsx(styles.content, className)}
    {...props}
  >
    {children}
  </DrawerPrimitive.Content>
))
DrawerInnerContent.displayName = 'Drawer.InnerContent'

const DrawerTrigger = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Trigger>
>((props, ref) => (
  <DrawerPrimitive.Trigger ref={ref} asChild data-drawer-trigger {...props} />
))

DrawerTrigger.displayName = 'Drawer.Trigger'

type DrawerContentProps = React.ComponentPropsWithoutRef<
  typeof DrawerPrimitive.Content
> & {
  /**
   * Whether the drawer has a handle.
   * @default true
   */
  handle?: boolean
  /**
   * Whether the drawer content should be rendered as child component.
   * @default false
   */
  asChild?: boolean
}

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerInnerContent>,
  DrawerContentProps
>(({ handle = true, children, ...props }, ref) => {
  const container = usePortalContainer()
  return (
    <DrawerPrimitive.Portal container={container}>
      <DrawerOverlay data-drawer-overlay>
        <DrawerInnerContent ref={ref} {...props}>
          {handle && (
            <DrawerPrimitive.Handle
              data-drawer-handle
              className={styles.handle}
            />
          )}
          {children}
        </DrawerInnerContent>
      </DrawerOverlay>
    </DrawerPrimitive.Portal>
  )
})
DrawerContent.displayName = 'Drawer.Content'

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof Heading>,
  React.ComponentPropsWithoutRef<typeof Heading>
>((props, ref) => (
  <DrawerPrimitive.Title data-drawer-title asChild>
    <Heading ref={ref} as="h3" size="small" {...props} />
  </DrawerPrimitive.Title>
))
DrawerTitle.displayName = 'Drawer.Title'

const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof Paragraph>,
  React.ComponentPropsWithoutRef<typeof Paragraph>
>((props, ref) => (
  <DrawerPrimitive.Description data-drawer-description asChild>
    <Paragraph ref={ref} {...props} />
  </DrawerPrimitive.Description>
))
DrawerDescription.displayName = 'Drawer.Description'

const DrawerClose = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Close>
>((props, ref) => <DrawerPrimitive.Close ref={ref} asChild {...props} />)
DrawerClose.displayName = 'Drawer.Close'

const DrawerHeader = React.forwardRef<
  React.ElementRef<'header'>,
  React.ComponentPropsWithoutRef<'header'>
>(({ className, ...props }, ref) => (
  <header ref={ref} className={clsx(styles.header, className)} {...props} />
))
DrawerHeader.displayName = 'Drawer.Header'

const DrawerBody = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    data-drawer-body
    className={clsx(styles.body, className)}
    {...props}
  />
))
DrawerBody.displayName = 'Drawer.Body'

const DrawerFooter = React.forwardRef<
  React.ElementRef<'footer'>,
  React.ComponentPropsWithoutRef<'footer'>
>(({ className, ...props }, ref) => (
  <footer
    ref={ref}
    data-drawer-footer
    className={clsx(styles.footer, className)}
    {...props}
  />
))
DrawerFooter.displayName = 'Drawer.Footer'

type DrawerSeparatorProps = React.ComponentPropsWithoutRef<'div'> & {
  /**
   * Whether the separator is inset.
   * @default true
   */
  inset?: boolean
}

const DrawerSeparator = React.forwardRef<
  React.ElementRef<'div'>,
  DrawerSeparatorProps
>(({ inset = true, className, ...props }, ref) => (
  <div
    ref={ref}
    data-drawer-separator
    className={clsx(styles.separator, inset && styles.inset, className)}
    {...props}
  />
))
DrawerSeparator.displayName = 'Drawer.Separator'

const DrawerObject = Object.assign(Drawer, {
  Trigger: DrawerTrigger,
  Content: DrawerContent,
  Header: DrawerHeader,
  Title: DrawerTitle,
  Description: DrawerDescription,
  Close: DrawerClose,
  Body: DrawerBody,
  Footer: DrawerFooter,
  Separator: DrawerSeparator,
})

export { DrawerObject as Drawer, useIsInDrawer }
export type { DrawerProps }
