import React from 'react'
import { calculateStyles } from '../lib/utils'
import {
  fontFamily,
  fontSize,
  fontSizeLarge,
  fontSizeExtraLarge,
  fontSizeMini,
  fontSizeSmall,
  fontSizeExtraSmall,
  fontWeightThin,
  fontWeightExtraLight,
  fontWeightLight,
  fontWeight,
  fontWeightMedium,
  fontWeightSemiBold,
  fontWeightBold,
  fontWeightExtraBold,
  fontWeightBlack,
} from '../style/font'
import {
  type whiteSpaces,
  normal,
  pre,
  preLine,
  preWrap,
  noWrap,
} from '../style/whiteSpaces'
import colors from '../style/color'

export interface TextProps {
  children?: React.ReactNode
  size?: 'extra-small' | 'large' | 'extra-large' | 'mini' | 'small'
  weight?:
    | 'extra-light'
    | 'light'
    | 'thin'
    | 'medium'
    | 'semi-bold'
    | 'bold'
    | 'extra-bold'
    | 'black'
  color?: keyof typeof colors
  whitespace?: (typeof whiteSpaces)[number]
  className?: string
}

const Text = ({
  children,
  size,
  weight,
  color = 'shuttleGray',
  whitespace,
  className,
}: TextProps): JSX.Element => {
  const style = calculateStyles(
    {
      default: {
        fontFamily,
        fontSize,
        fontWeight,
        color: colors[color],
      },
      // Weights
      extraLight: {
        fontWeight: fontWeightExtraLight,
      },
      light: {
        fontWeight: fontWeightLight,
      },
      thin: {
        fontWeight: fontWeightThin,
      },
      medium: {
        fontWeight: fontWeightMedium,
      },
      semiBold: {
        fontWeight: fontWeightSemiBold,
      },
      bold: {
        fontWeight: fontWeightBold,
      },
      extraBold: {
        fontWeight: fontWeightExtraBold,
      },
      black: {
        fontWeight: fontWeightBlack,
      },
      // Sizes
      extraSmall: {
        fontSize: fontSizeExtraSmall,
      },
      large: {
        fontSize: fontSizeLarge,
      },
      extraLarge: {
        fontSize: fontSizeExtraLarge,
      },
      mini: {
        fontSize: fontSizeMini,
      },
      small: {
        fontSize: fontSizeSmall,
      },
      preWrap: {
        whiteSpace: preWrap,
      },
      normal: {
        whiteSpace: normal,
      },
      preLine: {
        whiteSpace: preLine,
      },
      pre: {
        whiteSpace: pre,
      },
      noWrap: {
        whiteSpace: noWrap,
      },
    },
    {
      // Weights
      extraLight: weight === 'extra-light',
      light: weight === 'light',
      thin: weight === 'thin',
      medium: weight === 'medium',
      semiBold: weight === 'semi-bold',
      bold: weight === 'bold',
      extraBold: weight === 'extra-bold',
      black: weight === 'black',
      // Sizes
      extraSmall: size === 'extra-small',
      large: size === 'large',
      extraLarge: size === 'extra-large',
      mini: size === 'mini',
      small: size === 'small',
      // White-Space
      preWrap: whitespace === preWrap,
      normal: whitespace === normal,
      preLine: whitespace === preLine,
      pre: whitespace === pre,
      noWrap: whitespace === noWrap,
    },
  )
  return (
    <span style={style} className={className}>
      {children}
    </span>
  )
}

export default Text
