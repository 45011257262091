import * as React from 'react'
import { ChevronDownIcon } from '../icons/generated/ChevronDownIcon'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'

import styles from './Collapsible.module.css'
import clsx from 'clsx'
import { Flex } from '../Flex'
import { IconButton } from '../IconButton'

/**
 * Collapsible
 */
type CollapsibleProps = React.ComponentPropsWithoutRef<
  typeof CollapsiblePrimitive.Root
> & {
  /* open state when initially rendered */
  defaultOpen?: boolean
  /**
   * Whether the CollapsibleItem is open or not
   */
  open?: boolean

  /**
   * Callback to be called when the CollapsibleItem is opened
   */
  onOpenChange?: (open: boolean) => void
  /**
   * Content to render inside the Collapsible
   */
  children?: React.ReactNode
}
const Collapsible: React.FC<CollapsibleProps> = (props) => {
  return (
    <CollapsiblePrimitive.Root
      {...props}
      className={clsx(styles.collapse, props.className)}
    />
  )
}

Collapsible.displayName = 'Collapsible'

/**
 * Collapsible Trigger
 */
type CollapsibleTriggerProps = React.ComponentPropsWithoutRef<
  typeof CollapsiblePrimitive.Trigger
> & {
  children?: React.ReactNode
}

const CollapsibleTrigger = React.forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.Trigger>,
  CollapsibleTriggerProps
>(({ children, ...props }, forwardedRef) => (
  <CollapsiblePrimitive.Trigger
    {...props}
    ref={forwardedRef}
    className={clsx(styles.collapsibleTrigger, props.className)}
  >
    {props.asChild ? (
      children
    ) : (
      <Flex direction="row" justify="start">
        {children}
        <IconButton
          label="Open Close Collapsible"
          variant="tertiary"
          className={styles.triggerIcon}
        >
          <ChevronDownIcon />
        </IconButton>
      </Flex>
    )}
  </CollapsiblePrimitive.Trigger>
))

CollapsibleTrigger.displayName = 'Collapsible.Trigger'

type CollapsibleContentProps = React.ComponentPropsWithoutRef<
  typeof CollapsiblePrimitive.Content
> & {
  className?: string
}

const CollapsibleContent = React.forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.Content>,
  CollapsibleContentProps
>(({ children, ...props }, forwardedRef) => (
  <CollapsiblePrimitive.Content
    {...props}
    ref={forwardedRef}
    className={clsx(styles.collapsibleContent, props.className)}
  >
    {children}
  </CollapsiblePrimitive.Content>
))

CollapsibleContent.displayName = 'Collapsible.Content'
const CollapsibleObject = Object.assign(Collapsible, {
  Trigger: CollapsibleTrigger,
  Content: CollapsibleContent,
})

export { CollapsibleObject as Collapsible }
export type {
  CollapsibleProps,
  CollapsibleTriggerProps,
  CollapsibleContentProps,
}
