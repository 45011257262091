import React from 'react'
import clsx from 'clsx'

import { CloseIcon } from '../icons'

import styles from './Banner.module.css'
import { UnstyledButton } from '../UnstyledButton'
import { VisuallyHidden } from '../VisuallyHidden'
import { Button, ButtonProps } from '../Button'

type BannerProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * Visual variant of the banner
   */
  variant?: 'critical' | 'warning' | 'info'
  className?: string
  children: React.ReactNode
  /**
   * On dismiss callback, when passed a close button will be displayed
   */
  onDismiss?: () => void
}

type BannerTextProps = React.ComponentProps<'div'> & {
  className?: string
}

const BannerText = React.forwardRef<HTMLParagraphElement, BannerTextProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <p
        ref={forwardedRef}
        className={clsx(styles.text, className)}
        {...props}
      />
    )
  },
)

BannerText.displayName = 'Banner.Text'

enum BannerButtonVariants {
  critical = 'critical',
  warning = 'warning',
  info = 'primary',
}

const BannerAction = ({ className, children, ...props }: ButtonProps) => {
  const { variant } = useBanner()

  return (
    <Button
      className={className}
      size="small"
      variant={props?.variant || BannerButtonVariants[variant]}
      {...props}
    >
      {children}
    </Button>
  )
}

BannerAction.displayName = 'Banner.Action'

/**
 * Banner Context
 */
interface BannerContextValue {
  variant: 'critical' | 'warning' | 'info'
}

const BannerContext = React.createContext<BannerContextValue | null>(null)

const useBanner = () => {
  const context = React.useContext(BannerContext)

  if (!context) {
    throw new Error('useBanner must be used within a Banner')
  }

  return context
}

const Banner = React.forwardRef<HTMLDivElement, BannerProps>(
  (
    { variant = 'info', children, className, onDismiss, ...props }: BannerProps,
    forwardedRef,
  ) => {
    return (
      <BannerContext.Provider value={{ variant }}>
        <div
          ref={forwardedRef}
          role="status"
          className={clsx(styles.banner, styles[variant], className)}
          {...props}
        >
          <div className={styles.content}>{children}</div>
          {onDismiss && (
            <UnstyledButton className={styles.close} onClick={onDismiss}>
              <VisuallyHidden>Dismiss</VisuallyHidden>
              <CloseIcon />
            </UnstyledButton>
          )}
        </div>
      </BannerContext.Provider>
    )
  },
)

Banner.displayName = 'Banner'

const BannerObject = Object.assign(Banner, {
  Text: BannerText,
  Action: BannerAction,
})

export { BannerObject as Banner }
export type { BannerProps, BannerTextProps }
