import React from 'react'
import clsx from 'clsx'

import styles from './Table.module.css'

const Table = React.forwardRef<
  HTMLTableElement,
  React.ComponentProps<'table'> & {
    /**
     * When true, the table wrapper will take up 100% of the available width.
     * When false, the table wrapper will have a more contained width.
     * @default true
     */
    fullWidth?: boolean
  }
>(({ children, className, fullWidth = true, ...props }, forwardedRef) => {
  return (
    <div
      className={clsx(
        styles.tableWrapper,
        !fullWidth && styles.contained,
        className,
      )}
    >
      <table className={clsx(styles.table)} ref={forwardedRef} {...props}>
        {children}
      </table>
    </div>
  )
})
Table.displayName = 'Table'

const Header = React.forwardRef<
  HTMLTableSectionElement,
  React.ComponentProps<'thead'>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <thead
      className={clsx(styles.header, className)}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </thead>
  )
})
Header.displayName = 'Table.Header'

const Body = React.forwardRef<
  HTMLTableSectionElement,
  React.ComponentProps<'tbody'>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <tbody
      className={clsx(styles.body, className)}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </tbody>
  )
})
Body.displayName = 'Table.Body'

const Row = React.forwardRef<HTMLTableRowElement, React.ComponentProps<'tr'>>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <tr className={clsx(styles.row, className)} ref={forwardedRef} {...props}>
        {children}
      </tr>
    )
  },
)
Row.displayName = 'Table.Row'

const Cell = React.forwardRef<
  HTMLTableCellElement,
  React.ComponentProps<'td'> & {
    /**
     * Controls the alignment of the cell content
     * @default left
     */
    align?: 'left' | 'center' | 'right'
  }
>(({ children, className, align = 'left', ...props }, forwardedRef) => {
  return (
    <td
      className={clsx(styles.cell, className)}
      data-align={align}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </td>
  )
})
Cell.displayName = 'Table.Cell'

const HeaderCell = React.forwardRef<
  HTMLTableCellElement,
  React.ComponentProps<'th'> & {
    /**
     * Controls the alignment of the cell content
     * @default left
     */
    align?: 'left' | 'center' | 'right'
    /**
     * Specifies whether this header cell is for a row or column
     * @default column
     */
    type?: 'row' | 'column'
  }
>(
  (
    { children, className, align = 'left', type = 'column', ...props },
    forwardedRef,
  ) => {
    return (
      <th
        className={clsx(styles.headerCell, className)}
        data-align={align}
        ref={forwardedRef}
        scope={type}
        {...props}
      >
        {children}
      </th>
    )
  },
)
HeaderCell.displayName = 'Table.HeaderCell'

const TableObject = Object.assign(Table, {
  Header,
  Body,
  Row,
  Cell,
  HeaderCell,
})

export { TableObject as Table }
