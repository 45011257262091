import React, { Children } from 'react'
import clsx from 'clsx'

import { Avatar, AvatarProps } from '../Avatar/Avatar'
import { ChannelIcon, ChannelType } from '../ChannelIcon'
import { Flex } from '../Flex'
import { Badge } from '../Badge'
import { VisuallyHidden } from '../VisuallyHidden'

import styles from './ChannelAvatar.module.css'
import { PencilIcon } from '../icons/index'

export type ChannelAvatarSize = 'small' | 'mid' | 'medium' | 'large'

type ChannelAvatarProps = Omit<AvatarProps, 'ref'> & {
  /**
   * Channel type, used to determine the icon
   */
  channel: ChannelType
  /**
   * Size of the avatar
   * @default medium
   */
  size?: ChannelAvatarSize
  /**
   * Alternative text for the avatar image, set to `""` if the avatar is decorative
   */
  alt?: string
  /**
   * Source of the avatar image
   */
  src?: string
  /**
   * Optional click handler when passed Avatar will be rendered as a button and will show hover overlay with Pencil icon
   */
  onEditClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /**
   * Forces overlay to be shown
   */
  showEditOverlay?: boolean
}

const iconSizeByAvatarSize = {
  small: 10,
  mid: 12,
  medium: 12,
  large: 16,
}

const ChannelAvatarRoot = React.forwardRef<HTMLDivElement, ChannelAvatarProps>(
  (
    {
      channel,
      className,
      size = 'medium',
      showEditOverlay,
      onEditClick,
      style,
      ...props
    }: ChannelAvatarProps,
    forwardedRef,
  ) => {
    return (
      <div
        ref={forwardedRef}
        data-channel={channel}
        className={clsx(styles.wrapper, styles[size], className)}
        style={style}
      >
        <Avatar size={size} {...props} />
        {onEditClick && (
          <button
            onClick={onEditClick}
            aria-label="Edit avatar"
            className={clsx(
              styles.editable,
              showEditOverlay && styles.showEditOverlay,
            )}
          >
            <PencilIcon className={styles.editIcon} />
          </button>
        )}
        <div className={styles.icon}>
          <ChannelIcon
            type={channel}
            color="inverted"
            size={iconSizeByAvatarSize[size]}
          />
        </div>
      </div>
    )
  },
)

ChannelAvatarRoot.displayName = 'ChannelAvatar'

export interface ChannelAvatarStackProps
  extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * Whether the avatar should be on top or on bottom of the previous avatar
   * @default firstOnTop
   */
  stacking?: 'lastOnTop' | 'firstOnTop'
  /**
   * Number of max avatars to show in the stack
   */
  max?: number
  /**
   * Size of the avatar stack
   * @default medium
   */
  size?: ChannelAvatarSize
}

/**
 * ChannelAvatarStack is a component used to display channel avatar groups, by stacking avatars
 * on top of each other. It also displays a badge with the count of remaining
 * avatars that are not visible.
 *
 * You can customize the size of the avatar stack with the following CSS variables:
 *
 * `--avatar-stack-border-width` to change the border width of the avatars
 * `--avatar-stack-margin-left` to change the margin left of the avatars
 * `--avatar-stack-border-color` to change the border color of the avatars
 */
const ChannelAvatarStack = React.forwardRef<
  HTMLDivElement,
  ChannelAvatarStackProps
>(
  (
    {
      children: _children,
      stacking = 'firstOnTop',
      max,
      size = 'medium',
      style,
      className,
      ...props
    }: ChannelAvatarStackProps,
    forwardedRef,
  ) => {
    const childrenArray = Children.toArray(_children)
    const remaining = Math.max(max ? childrenArray.length - max : 0, 0)

    const children = childrenArray.slice(0, max)

    const calculateZIndex = (index: number) => {
      if (stacking === 'firstOnTop') {
        return (max ?? childrenArray.length) + 1 - index
      }
      return index
    }

    return (
      <Flex
        align="center"
        className={clsx(styles[size], styles.stack, className)}
        style={style}
        ref={forwardedRef}
        {...props}
      >
        {children.map((child, index) => (
          <div
            key={index}
            className={styles.avatarWrapper}
            style={
              {
                '--avatar-stack-z-index': calculateZIndex(index),
              } as React.CSSProperties
            }
          >
            {child}
          </div>
        ))}
        {remaining > 0 && (
          <Badge
            className={styles.badge}
            style={
              {
                '--avatar-stack-z-index': calculateZIndex(children.length),
              } as React.CSSProperties
            }
            size={size === 'large' ? 'large' : size}
          >
            +{remaining}
            <VisuallyHidden>more channel avatars</VisuallyHidden>
          </Badge>
        )}
      </Flex>
    )
  },
)
ChannelAvatarStack.displayName = 'ChannelAvatarStack'

const ChannelAvatar = Object.assign(ChannelAvatarRoot, {
  Stack: ChannelAvatarStack,
})

export { ChannelAvatar, ChannelAvatarProps }
