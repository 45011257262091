import clsx from 'clsx'
import React from 'react'

import { useControllableState } from '../../hooks/useControllableState'
import useId from '../../helpers/useId'
import { UnstyledButton } from '../UnstyledButton'
import { Text } from '../Text'
import { MinusIcon, PlusIcon } from '../icons'

import styles from './CounterInput.module.css'

type SetStateFn<T> = (prevState?: T) => T

type CounterInputProps = Omit<
  React.ComponentPropsWithoutRef<'input'>,
  'type' | 'value' | 'onChange' | 'size'
> & {
  /**
   * Size of the input counter
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Current value
   */
  value: number
  /**
   * Default value
   */
  defaultValue?: number
  /**
   * Minimum value allowed
   * @default 0
   */
  min?: number
  /**
   * Maximum value allowed
   */
  max?: number
  /**
   * Step value for increment/decrement
   * @default 1
   */
  step?: number
  /**
   * Callback when value changes
   */
  onChange: (value: number) => void
}

const iconSizebySize = {
  small: 'xsmall',
  medium: 'small',
  large: 'medium',
} as const

const CounterInput = React.forwardRef<HTMLInputElement, CounterInputProps>(
  (
    {
      className,
      size = 'medium',
      value: _value,
      defaultValue,
      min = 0,
      max,
      step = 1,
      onChange: _onChange,
      disabled,
      ...props
    }: CounterInputProps,
    forwardedRef,
  ) => {
    const [value, onChange] = useControllableState({
      prop: _value,
      defaultProp: defaultValue,
      onChange: _onChange,
    })
    const hintId = useId()

    const handleChangeValue = (nextValue: React.SetStateAction<number>) => {
      onChange((preValue) => {
        const setter = nextValue as SetStateFn<number>
        let value =
          typeof nextValue === 'function' ? setter(preValue) : nextValue

        if (!isNaN(value)) {
          if (min !== undefined && value < min) {
            value = min
          }
          if (max !== undefined && value > max) {
            value = max
          }
        }

        return value
      })
    }

    const handleDecrement = () => {
      if (disabled) return
      handleChangeValue((value ?? 0) - step)
    }

    const handleIncrement = () => {
      if (disabled) return
      handleChangeValue((value ?? 0) + step)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (disabled) return

      switch (event.key) {
        case 'ArrowUp':
        case 'ArrowRight':
          event.preventDefault()
          handleIncrement()
          break
        case 'ArrowDown':
        case 'ArrowLeft':
          event.preventDefault()
          handleDecrement()
          break
        case 'Home':
          event.preventDefault()
          if (min !== undefined) {
            handleChangeValue(min)
          }
          break
        case 'End':
          event.preventDefault()
          if (max !== undefined) {
            handleChangeValue(max)
          }
          break
      }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChangeValue(parseInt(e.target.value, 10))
    }

    return (
      <div className={clsx(styles.base, styles[size], className)}>
        <UnstyledButton
          className={styles.button}
          onClick={handleDecrement}
          disabled={disabled || (min !== undefined && (value ?? 0) <= min)}
          aria-label="Decrease value"
        >
          <MinusIcon size={iconSizebySize[size]} />
        </UnstyledButton>
        <div className={styles.inputWrapper}>
          <input
            type="text"
            role="spinbutton"
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            inputMode="numeric"
            pattern="[0-9]*"
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            aria-describedby={hintId}
            {...props}
            className={styles.input}
            ref={forwardedRef}
          />
        </div>
        <UnstyledButton
          className={styles.button}
          onClick={handleIncrement}
          disabled={disabled || (max !== undefined && (value ?? 0) >= max)}
          aria-label="Increase value"
        >
          <PlusIcon size={iconSizebySize[size]} />
        </UnstyledButton>

        {(value === max || value === min) && (
          <Text size="sm" color="subtle" id={hintId} className={styles.hint}>
            {value === max ? `Max number is ${max}` : `Min number is ${min}`}
          </Text>
        )}
      </div>
    )
  },
)

CounterInput.displayName = 'CounterInput'

export { CounterInput }
export type { CounterInputProps }
