// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const GoalHalfIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'goal-half'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.47713 2 2 6.47713 2 12C2 17.5229 6.47713 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47713 17.5229 2 12 2ZM5.33333 12C5.33333 8.31795 8.31795 5.33333 12 5.33333L12 18.6667C8.31795 18.6667 5.33333 15.682 5.33333 12Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
GoalHalfIcon.displayName = 'GoalHalfIcon'
export { GoalHalfIcon }
